import React, { useEffect } from "react";

function RegisterPage() {
  useEffect(() => {
    window.location.href =
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120022/DTD-2025-Brochure_LR.pdf";
  }, []);
  
  return <></>;
}

export default RegisterPage;
